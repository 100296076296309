<template>
  <div id="data-list-list-view" class="data-list-container">

    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>
    <vs-prompt title="Exportar a Excel" class="export-options" @cancle="clearFields" @accept="exportToExcelOrders"
               accept-text="Exportar" cancel-text="Cancelar" @close="clearFields" :active.sync="activePromptOrders">
      <vs-input v-model="fileName" placeholder="Ingresa el nombre del archivo..." class="w-full"/>
      <v-select v-model="selectedFormat" :options="formats" class="my-4"/>
      <div class="flex">
        <span class="mr-4">Auto ajustar columna:</span>
        <vs-switch v-model="cellAutoWidth">Auto ajustar columna</vs-switch>
      </div>
    </vs-prompt>

    <vx-card collapse="false" ref="filterCard" title="Filtros" class="user-list-filters mb-8" collapse-action
             refresh-content-action @refresh="resetFilters">
      <div class="vx-row">
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Filtrar por estado</label>
          <v-select :options="statesOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="stateFilter" class="mb-4 md:mb-0"/>
        </div>
      </div>
      <h5 class="mt-5 mb-2">Filtrar por fecha</h5>
      <div class="vx-row">
        <div class="vx-col md:w-1/2">
          <flat-pickr :config="configdateTimePicker" v-model="startDate"
                      placeholder="Desde"
                      class="w-full"/>
        </div>
        <div class="vx-col md:w-1/2">
          <flat-pickr :config="configdateTimePicker" v-model="endDate"
                      placeholder="Hasta"
                      class="w-full"/>
        </div>
        <div class="vx-col mt-2">
          <vs-button @click="filterByDate" class="mr-6 vs-align-right">Filtrar</vs-button>
        </div>
      </div>
    </vx-card>

    <detail @update="updateDataSideBar" :departments="departments" :provinces="provinces" :districts="districts"
            :products="productsDetail"
            :isSidebarActive="newSidebar" @closeSidebar="toggleDetail"
            :data="sidebarData"/>

    <vs-table v-model="selected" ref="table" pagination :max-items="itemsPerPage" search
              :data="list">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- Action - dropnow -->
          <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4">
            <div
                class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full">
              <span class="mr-2">Acciones</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="orderDetail(selected)">
                            <span class="flex items-center">
                              <feather-icon icon="EditIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Ver detalle</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="printGo(selected)">
                            <span class="flex items-center">
                              <feather-icon icon="PrinterIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Imprimir</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="activePromptOrders=true">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Exportar</span>
                            </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- Items per page -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{
                currentPage * itemsPerPage - (itemsPerPage - 1)
              }} - {{ list.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : list.length }} de {{
                queriedItems
              }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
          </div>
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=30">
              <span>30</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=40">
              <span>40</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="number">Id</vs-th>
        <vs-th sort-key="date">Fecha de pedido</vs-th>
        <vs-th sort-key="date">Fecha de entrega</vs-th>
        <vs-th sort-key="date">Hora de pedido</vs-th>
        <vs-th sort-key="subTotal">Subtotal</vs-th>
        <vs-th sort-key="discount">Descuento</vs-th>
        <vs-th sort-key="shipping">Costo de envío</vs-th>
        <vs-th sort-key="total">Total</vs-th>
        <vs-th sort-key="state">Forma de pago</vs-th>
        <vs-th sort-key="state">Estado</vs-th>
        <vs-th sort-key="clientName">Cliente</vs-th>
        <vs-th sort-key="clientDocumento">Distrito</vs-th>
        <vs-th sort-key="coupon">Cupón</vs-th>
        <vs-th>Acciones</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.id.substr(0, 6).toUpperCase() }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.createdAt | date(true) }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">{{ moment(tr.deliveryDate).format('DD-MM-YYYY') }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.createdAt | time(true) }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">S/ {{ (tr.total - tr.delivery) | round }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">S/ {{ tr.discount | round }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">S/ {{ tr.delivery | round }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">S/ {{ parseFloat((tr.total - tr.delivery) - tr.discount + tr.delivery) | round }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">
              {{ tr.orderVisa ? tr.orderVisa.dataMap.BRAND.toUpperCase() : '-' }}</p>
          </vs-td>

          <vs-td>
            <vs-chip :color="getOrderStatusColor(tr.state)" class="product-order-status">
              {{ getStateText(tr.state) | title }}
            </vs-chip>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.name + ' ' + tr.lastName }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">
              {{ districts.find(d => d.codDistrito === tr.district).nomDistrito }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.coupon.code ? tr.coupon.code : 'NN' }}</p>
          </vs-td>

          <vs-td class="whitespace-no-wrap">
            <feather-icon class="ml-2" icon="FileTextIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="orderDetail(tr)"/>

            <feather-icon class="ml-2" icon="PrinterIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="printGo(tr)"/>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import Detail from './Detail'
import print from 'print-js'
import vSelect from 'vue-select'
import moment from "moment"
import { db, Timestamp } from '@/firebase/firebaseConfig'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

import departments from '../../data/ubigeo/departments.json'
import provinces from '../../data/ubigeo/province.json'
import districts from '../../data/ubigeo/district.json'

let _ = require('lodash')

export default {
  components: {
    Detail,
    vSelect,
    flatPickr
  },
  data () {
    return {
      selected: null,
      itemsPerPage: 20,
      isMounted: false,
      list: [],
      products: [],
      productsDetail: [],
      initProgress: false,

      //Filters
      filter: false,

      statesOptions: [
        { label: 'Todos', value: -1 },
        { label: 'Sin confirmar', value: 'hold' },
        { label: 'En proceso', value: 'in_process' },
        { label: 'Aprobado', value: 'approved' },
        { label: 'Rechazado', value: 'rejected' },
        { label: 'Cancelado', value: 'cancel' },
        { label: 'Entregado', value: 'delivered' }
      ],
      deliveriesOptions: [
        { label: 'Todos', value: -1 }
      ],

      stateFilter: {
        label: 'Todos',
        value: -1
      },

      startDate: '',
      endDate: '',

      //

      // Date
      configdateTimePicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },

      // Data Sidebar
      newSidebar: false,
      updateSidebar: false,
      featureSideBar: false,
      sidebarData: {},
      // Export
      fileName: '',
      formats: ['xlsx', 'csv', 'txt'],
      cellAutoWidth: true,
      selectedFormat: 'xlsx',
      activePrompt: false,
      activePromptOrders: false,

      // Ubigeo
      departments: departments,
      provinces: provinces,
      districts: districts,
      moment
    }
  },
  async created () {
    try {
      this.initProgress = true
      await this.loadOrders()
    } catch (e) {
      this.$vs.notify({
        color: 'warning',
        title: '¡Oh no!',
        text: 'Hubo un error.'
      })
    } finally {
      this.initProgress = false
    }
  },
  mounted () {
    this.isMounted = true
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.list.length
    }
  },
  watch: {
    /**
     * Filter by state
     * @param val
     * @returns {Promise<void>}
     */
    async stateFilter (val) {
      try {
        if (val.value !== -1) {
          this.initProgress = true
          this.list = []
          const querySnapshot = await db.collection('orders')
              .where('state', '==', this.stateFilter.value)
              .orderBy('createdAt', 'desc').limit(100).get()
          querySnapshot.forEach((doc) => {
            let order = {
              id: doc.id,
              ...doc.data()
            }
            if (doc.data().observation) {
              order.observation = doc.data().observation
            }
            this.list.push(order)
          })
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.initProgress = false
      }
    }
  },
  methods: {
    /**
     * Get data of firestore
     * @returns {Promise<void>}
     */
    async loadOrders () {
      this.list = []
      //Get orders
      const querySnapshot = await db.collection('orders').orderBy('createdAt', 'desc').limit(100).get()
      querySnapshot.forEach((doc) => {
        let order = {
          id: doc.id,
          ...doc.data(),
          coupon: doc.data().coupon || {},
          createdAt: doc.data().createdAt.toDate(),
          deliveryDate: typeof doc.data().deliveryDate === 'string' ? new Date(doc.data().deliveryDate) : doc.data().deliveryDate.toDate()
        }
        order.id = doc.id
        if (doc.data().observation) {
          order.observation = doc.data().observation
        }
        this.list.push(order)
      })
    },
    /**
     * Generate PDF
     * @param o
     */
    printGo (o) {
      const order = _.cloneDeep(o)
      let products = []
      order.products.forEach((p) => {
        let product = {
          name: p.name + ' - ' + (p.selectedColor.name ? p.selectedColor.name : '') + ' - ' + (p.selectedOption.name ? p.selectedOption.name : '') + '<br>' + p.selectedCard + '<br> Titulo: ' + p.cardTitle + '<br> Mensaje: ' + p.cardMessage,
          sku: p.sku,
          price: p.priceDiscount,
          quantity: p.quantity
        }
        products.push(product)
      })

      const department = this.departments.find(d => d.codDepartamento === order.department).nomDepartamento
      const province = this.provinces.find(p => p.codProvincia === order.province).nomProvincia
      const district = this.districts.find(d => d.codDistrito === order.district).nomDistrito

      print({
        printable: products,
        type: 'json',
        properties: [
          { field: 'name', displayName: 'Nombre' },
          { field: 'sku', displayName: 'SKU' },
          { field: 'price', displayName: 'Precio' },
          { field: 'quantity', displayName: 'Cantidad' },
        ],
        header: `<h3 class="custom-h3">${order.id.substr(0, 6).toUpperCase()}</h3> <br> ${order.name} ${order.lastName} <br> ${order.phone} <br>  ${order.address + ' - ' + department + ' - ' + province + ' - ' + district} <br> Fecha de entrega: ${this.$options.filters.date(order.deliveryDate, true)} <br><br></h3>`,
      })
    },
    /**
     * Filter by date
     * @returns {Promise<void>}
     */
    async filterByDate () {
      try {
        this.initProgress = true
        this.list = []
        const starTimes = Timestamp.fromDate(new Date(this.startDate))
        const endTimes = Timestamp.fromDate(new Date(this.endDate))
        let querySnap = ''
        if (this.stateFilter.value === -1) {
          querySnap = await db.collection('orders')
              .where('createdAt', '>=', starTimes)
              .where('createdAt', '<=', endTimes)
              .orderBy('createdAt', 'desc')
              .get()
        }
        else {
          querySnap = await db.collection('orders')
              .where('createdAt', '>=', starTimes)
              .where('createdAt', '<=', endTimes)
              .where('state', '==', this.stateFilter.value)
              .orderBy('createdAt', 'desc').get()
        }

        querySnap.forEach((doc) => {
          let order = {
            id: doc.id,
            ...doc.data(),
            coupon: doc.data().coupon || {},
            createdAt: doc.data().createdAt.toDate(),
            deliveryDate: typeof doc.data().deliveryDate === 'string' ? new Date(doc.data().deliveryDate) : doc.data().deliveryDate.toDate()
          }
          this.list.push(order)
        })
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.initProgress = false
      }
    },
    /**
     * Reset filters
     * @returns {Promise<void>}
     */
    async resetFilters () {
      try {
        this.initProgress = true
        await this.loadOrders()

        this.stateFilter = {
          label: 'Todos',
          value: -1
        }
        this.$refs.filterCard.removeRefreshAnimation()
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.initProgress = false
      }
    },
    orderDetail (data) {
      this.sidebarData = data
      this.toggleDetail(true)
    },
    toggleDetail (val = false) {
      this.newSidebar = val
    },
    getOrderStatusColor (status) {
      if (status === 'hold') return 'warning'
      if (status === 'approved') return 'dark'
      if (status === 'confirmed') return 'dark'
      if (status === 'invoiced') return 'success'
      if (status === 'rejected') return 'danger'
      if (status === 'cancel') return 'danger'
      return 'success'
    },
    getStateText (value) {
      if (value === 'hold') return 'Sin confirmar'
      if (value === 'in_process') return 'En proceso'
      if (value === 'approved') return 'Aprobado '
      if (value === 'confirmed') return 'Confirmado '
      if (value === 'invoiced') return 'Facturado '
      if (value === 'rejected') return 'Rechazado '
      if (value === 'cancel') return 'Cancelado '
      if (value === 'delivered') return 'Entregado'
    },
    filterData () {
      this.filter = !this.filter
    },
    exportToExcelOrders () {
      try {
        this.initProgress = true
        let orders = _.cloneDeep(this.list)
        // Calculate pre oders
        let headerTitle = ['ID', 'Fecha de pedido', 'Fecha de entrega', 'Hora de entrega', 'Cliente', 'Celular', 'Email', 'Departamento', 'Provincia', 'Distrito', 'Dirección', 'Producto Cantidad', 'Producto precio', 'Delivery', 'Producto SKU', 'Producto Nombre', 'Producto Color Caja', 'Producto Opción', 'Producto Mensaje']
        let headerVal = ['number', 'createdAt', 'deliveryDate', 'scheduleDate', 'client', 'phone', 'email', 'department', 'province', 'district', 'address', 'productQuantity', 'productPrice', 'delivery', 'productSku', 'productDescription', 'productColor', 'productOption', 'productMessage']
        import('@/vendor/Export2Excel').then(async excel => {
          const list = []
          orders.forEach((o) => {
            o.products.forEach((p) => {
              const obj = {
                createdAt: moment(o.createdAt).format('DD-MM-YYYY'),
                deliveryDate: moment(o.deliveryDate).format('DD-MM-YYYY'),
                scheduleDate: o.scheduleDate,
                number: o.id.substr(0, 6).toUpperCase(),
                client: o.name + ' ' + o.lastName,
                typeDocument: 'DNI',
                document: o.document,
                phone: o.phone,
                email: o.email,
                department: this.departments.find(d => d.codDepartamento === o.department).nomDepartamento,
                province: this.provinces.find(p => p.codProvincia === o.province).nomProvincia,
                district: this.districts.find(d => d.codDistrito === o.district).nomDistrito,
                address: o.address,
                productsList: o.productsList,
                productQuantity: p.quantity,
                productPrice: p.priceDiscount,
                delivery: parseFloat((o.delivery / o.products.length).toFixed(3)),
                productSku: p.sku,
                productDescription: p.name,
                productColor: (p.selectedColor.name ? p.selectedColor.name : ''),
                productOption: (p.selectedOption.name ? p.selectedOption.name : ''),
                productMessage: (p.selectedCard ? p.selectedCard : '') + '\n' + 'Titulo: ' + p.cardTitle + '\n' + 'Mensaje: ' + p.cardMessage
              }
              list.push(obj)
            })
          })
          const data = this.formatJson(headerVal, list)
          excel.export_json_to_excel({
            header: headerTitle,
            data,
            filename: this.fileName,
            autoWidth: this.cellAutoWidth,
            bookType: this.selectedFormat
          })
          this.clearFields()
          this.initProgress = false
        })
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
        this.initProgress = false
      }
    },
    clearFields () {
      this.filename = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      }))
    },
    dateFormat (value, fullDate) {
      value = String(value)
      const date = value.slice(8, 10).trim()
      const month = value.slice(4, 7).trim()
      const year = value.slice(11, 15)

      if (!fullDate) return `${date} ${month}`
      else return `${date} ${month} ${year}`
    },
    /**
     * Update data of sidebar
     * */
    updateDataSideBar (o) {
      const index = this.list.findIndex((d) => d.id === o.id)
      Object.assign(this.list[index], o)
    }
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
